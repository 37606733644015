<template>
    <section class="content">
        <table class="table table-hover" ref="tablesuswa">
            <thead>
                <tr>
                    <th>NAMA</th>
                    <th>JK</th>
                    <th>BIRTH DATE</th>
                    <th>ALAMAT</th>
                    <th>NO. KTP</th>
                    <th>NO. HP</th>
                    <th>ASAL SEKOLAH</th>
                    <th>JURUSAN SEKOLAH</th>
                    <th>STATUS</th>
                    <th>TINDAKAN</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";

export default {
    name: 'Siswalist',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            id: '',
            branch: ''
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        // this.id = this.$route.params.id
        // this.branch = this.$route.params.branch
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }

            if (e.target.closest("button")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "detail") {
                    var id = btnEl.dataset.id;
                    this.$router.push('/rekap-ujian/siswa/'+id);
                }
                return false;
            }
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tablesuswa, {
            "title": "List Data",
            "roles": ["read"],
            "ajax": "/akademik/ujian/per_kelas/"+self.$route.params.id,
            scrollX: true,
            "columns": [
                { "data": "display_name" },
                { "data": "gender" },
                { "data": "birth_date" },
                { "data": "addr_detail" },
                { "data": "no_ktp" },
                { "data": "no_hp" },
                { "data": "asal_sekolah" },
                { "data": "jurusan_sekolah" },
                { "data": "status" },
                { "data": "action",
                render: function (data, type, row, meta) {
                    return `<div class="btn-group">
                    <button type="button" class="btn btn-sm btn-outline-info" data-action="detail" data-id="` + row.id +`"><i class="fas fa-eye"></i> Detail</button>
                    </div>`
                } },
            ],
            filterBy: [0, 1, 2, 3, 4, 5, 6, 7],
            "rowCallback": function(row, data) {
                if (data.status > 0) {
                    $('td:eq(8)', row).html('<a class="badge badge-success" style="color:white">Active</a>');
                } else {
                    $('td:eq(8)', row).html('<a class="badge badge-success" style="color:white">Not Active</a>');
                }
            },
            // paramData: function(d) {
            // if (self.$route.params.id) {
            //     d.siswa_id = self.$route.params.id;
            // } else {
            //     d.siswa_id = 0;
            // }
            // },
        });
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
</style>